import { BairroLogradouro, Region, Supply } from '@sq/data/schemas'

import { EventPayload, EventTypes } from '@/components/tracks/types'

import { SupplyQuery, SupplyResponse } from '../supply/supply.gateway'
import { Range, SearchValue, SupplySearchParams } from './search.model'

export const createQuerySearch = (value: string): SearchValue => ({ type: 'query', value })

export const createRegionSearch = (value: Region): SearchValue => ({ type: 'region', value })

export const createLogradouroSearch = (logradouro: BairroLogradouro): SearchValue => ({
    type: 'logradouro',
    value: logradouro,
})

export const toSearchText = (value: SearchValue): string => {
    switch (value.type) {
        case 'query':
            return value.value
        case 'region':
            return value.value.bairro
        case 'logradouro':
            return [value.value.logradouro, value.value.bairro].join(', ')
    }
}

export const formatPrice = (price: number): string =>
    price.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: 0,
    })

export const priceToText = (price: Range): string => {
    if (price.type === 'open') {
        return `a partir de ${formatPrice(price.min)}`
    }
    return `${formatPrice(price.min)} a ${formatPrice(price.max)}`
}

export const sizeToText = (size: Range): string => {
    if (size.type === 'open') {
        return `a partir de ${size.min} m²`
    }
    return `${size.min} m² a ${size.max} m²`
}

export const rangeToSliderValue = (range: Range, defaultMax: number): readonly [number, number] => {
    if (range.type === 'open') {
        return [range.min, defaultMax]
    }

    return [range.min, range.max]
}

export const queryToSearchPathName = ({ type, value }: SearchValue): string => {
    switch (type) {
        case 'query':
            return 'imoveis/aluguel/all'
        case 'region':
            return `imoveis/aluguel/${value.bairro_slug}`
        case 'logradouro':
            return `imoveis/aluguel/${value.bairro_slug}/${value.logradouro_slug}`
    }
}

export const rangeToQueryString = (range: Range): string => {
    if (range.type === 'closed') {
        return `${range.min}_${range.max}`
    }

    return `${range.min}_`
}

export const toSearchUrl = ({ query, size, price }: SupplySearchParams): string => {
    const qs = new URLSearchParams()
    if (price) qs.set('preço', rangeToQueryString(price))
    if (size) qs.set('tamanho-do-imovel', rangeToQueryString(size))

    return queryToSearchPathName(query) + '?' + qs.toString()
}

export function stripRange(range: string): Range {
    const [parsedMin, parsedMax] = range.split('_').map((v) => parseInt(v))

    let min = 0
    let max = undefined

    if (Number.isSafeInteger(parsedMin)) {
        min = parsedMin
    }

    if (Number.isSafeInteger(parsedMax)) {
        max = parsedMax
    }

    if (max !== undefined) {
        return { min, max, type: 'closed' }
    }

    return { min, type: 'open' }
}

export function extractFirstName(name: string) {
    const names = name.split(' ')
    if (names.length > 0) {
        return names[0]
    }
    return name
}

export function extractLastName(name: string) {
    const names = name.split(' ')
    if (names.length > 1) {
        return names.slice(1).join(' ')
    }
    return name
}

export function normalizePrice(supply: Supply): number {
    const price = supply.details['preço']?.value ?? '0'
    return Number.parseInt(price) / 100
}

export function searchToEvent(search: SupplyQuery, results: SupplyResponse): EventPayload {
    const top5 = results.data?.length > 0 ? results.data.slice(0, 5) : []
    return {
        event_name: EventTypes.SEARCH,
        custom_data: {
            content_type: 'home_listing',
            search_string: searchStringToEvent(search),
            content_ids: top5.map((s) => s.slug),
            city: 'saopaulo',
            region: 'saopaulo',
            country: 'br',
            currency: 'BRL',
        },
    }
}

export function searchStringToEvent(search: SupplyQuery): string {
    var search_string = ''
    if (search.address) {
        search_string = search.address
    }

    if (search.regions && search.regions.length > 0) {
        search_string = search.regions[0].bairro
    }

    if (search.filters && search.filters.length > 0) {
        if (search_string != '') {
            search_string += ' - '
        }

        search_string += search.filters
            .map((f) => {
                return `${f.name}:${f.value}`
            })
            .join(' | ')
    }

    if (search.sort) {
        if (search_string != '') {
            search_string += ' - '
        }

        search_string += `${search.sort.name}:${search.sort.sort}`
    }

    return search_string
}
