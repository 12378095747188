export type User = {
    name: string
    email: string
    phone: string
    profile_completed: boolean
    business_type: string
    business_type_outro: string
    deal_deadline: string
    optIn: boolean
    coupon?: {
        code: string
        discount?: number
    }
}

export const BUSINESS_TYPE_OUTRO_VALUE = 'Outros'

export const ProfileBusinessTypes = [
    'Alimentação e bebidas',
    'Automotivo',
    'Beleza e estética',
    'Educação',
    'Entretenimento e lazer',
    'Escritório',
    'Indústria e distribuição',
    'Pet',
    'Religião',
    'Saúde e bem-estar',
    'Varejo especializado',
    'Vestuário',
    'Serviço especializado',
]

export const ProfileDealDeadlines = [
    'O quanto antes, tenho urgência para alugar',
    'Posso esperar um pouco, não tenho um prazo definido',
    'Ainda estou explorando opções e não tenho pressa',
]
